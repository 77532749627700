import { 
  ReactElement, 
  useState 
} from "react";

import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { useAuth } from "../../AuthProvider";
import { useData } from "../../DataProvider";
import Course from '../../types/Course';
import GratiCard from "../common/GratiCard";

interface CourseCardProps {
  course: Course;
  variant?: "elevation" | "outlined" | "none";
}

export default function CourseCard(props: CourseCardProps): ReactElement {
  const { course } = props;
  const variant = props.variant ?? "outlined";
  const { isGod } = useAuth();
  const { deleteCourse, isOrgAdmin } = useData();

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const deleteThisCourse = (id: string) => {
    console.log(`Deleting event ${id}`);
    deleteCourse(id);
    setDeleteConfirm(false);
  };


  const StyledType = (): string => {
    return (
      course.membership ? course.membership : ""
    );
  }

  const ActionBar = (): ReactElement => {
    return (
      <Stack spacing={2}>
        {course.usgaCourseId &&
          <Button 
            variant="contained" 
            color="secondary" 
            component="a" 
            target="_blank"
            href={`https://ncrdb.usga.org/courseTeeInfo?CourseID=${course.usgaCourseId}`}
            endIcon={<OpenInNewIcon />}
          >
            USGA Course Info
          </Button>
        }
      </Stack>
    );
  }

  return (
    <GratiCard 
      to={`/course/${course.itemId}`} 
      key={course.itemId}
      variant={variant}
      overline={StyledType()}
      avatar={course.logo}
      title={course.name} 
      description={"Par: " + course.par}
      placeId={course.googlePlaceId}
      footer={["Timezone: " + course.timezone]}
      actionbar=<ActionBar />
    />
  );
}