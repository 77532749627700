import { 
  useState, 
  useEffect
} from 'react';

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface Location {
    lat: number;
    lng: number;
}

interface GratiMapProps {
    placeId: string;
}

export default function GratiMap(props: GratiMapProps) {
  const { placeId } = props;
  const [location, setLocation] = useState<Location | null>(null);

  useEffect(() => {
    const getPlaceDetails = async () => {
      if (!placeId || !window.google?.maps?.places) {
        return;
      }
      const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

      placesService.getDetails({ placeId }, (place, status) => {
        if (status === 'OK' && place && place.geometry && place.geometry.location) {
          setLocation(place.geometry.location.toJSON());
        }
      });
    };
    getPlaceDetails();
  }, [placeId]);

  return (
    <LoadScript googleMapsApiKey="YOUR_API_KEY">
      {location && (
        <GoogleMap
          mapContainerStyle={{ height: '400px', width: '100%' }}
          center={location}
          zoom={14}
        >
          <Marker position={location} />
        </GoogleMap>
      )}
    </LoadScript>
  );
};