import { 
  ReactElement, 
  useEffect, 
  useState
} from "react";

import { doc } from "firebase/firestore";

import { useNavigate, useParams } from "react-router-dom";

import { useDocumentData } from 'react-firebase-hooks/firestore';

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";

import EditIcon from '@mui/icons-material/Edit';

import { useApp } from "../AppProvider";
import { useAuth } from "../AuthProvider";
import Hourglass from "../components/common/Hourglass";
import CourseCard from "../components/course/CourseCard";
import CourseEditor from "../components/course/CourseEditor";
import { useData } from "../DataProvider";
import Course from "../types/Course";
import { Container } from "@mui/material";

interface CourseProps {
  edit?: boolean;
}
export default function CoursePage(props: CourseProps): ReactElement {
  const [isEditMode, setIsEditMode] = useState(props.edit ? props.edit : false);
  const { setActions, setPageName } = useApp();

  const { isGod } = useAuth();
  const { coursesCol, isOrgAdmin, } = useData();

  const { courseId } = useParams();
  const navigate = useNavigate();

  const [course, isCourseLoading, courseLoadingError] = useDocumentData<Course>(doc(coursesCol, courseId));

  useEffect(() => {
    if (!isCourseLoading && (courseLoadingError || !course)) {
      console.log('Course not found');
    }
    if (course) {
      setPageName(course.name);
      if (isGod || (course.orgId && isOrgAdmin(course.orgId))) {
        setActions([
          {
            name: 'Edit',
            handleClick: handleEditClick,
            icon: EditIcon,
          },
        ]);
      }
    }
  }, [course, isCourseLoading, courseLoadingError, isGod, isOrgAdmin, setActions, setPageName]);

  const handleEditClick = () => {
    if (course) {
      navigate('/course-edit/' + course.itemId, { replace: true });
    }
    setIsEditMode(true);
  };


  const CourseDetails = (): ReactElement => {
    if (course) {
      return (
        <Stack spacing={2}>
          <CourseCard course={course} variant="none"/>
          <Divider sx={{ display: { sm: 'none' } }} />
        </Stack>
      );
    } else {
      return <></>;
    }
  };

  const Holes = (): ReactElement => {
    if (course) {
      return (<Box></Box>);
    } else {
      return (<Box></Box>);
    }
  };

  if (course) {
    if (isGod || (course.orgId && isOrgAdmin(course.orgId))) {
      return (
        <>
          {isEditMode ? <CourseEditor course={course} isNewCourse={false} /> : <CourseDetails />}
        </>
      );
    } else {
      return <CourseDetails />;
    }
  } else {
    return (<Hourglass />);
  }
};