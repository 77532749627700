import React, { 
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

import { 
  NavigateOptions, 
  useLocation } 
from 'react-router-dom';

import SvgIcon from "@mui/material/SvgIcon";

import EventIcon from "@mui/icons-material/Event";
import GolfCourseIcon from "@mui/icons-material/GolfCourse";
import HomeIcon from "@mui/icons-material/Home";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SportsGolfIcon from "@mui/icons-material/SportsGolf";

/* import MahaloIcon from "./icons/MahaloIcon"; */

export interface NavigateToProps {
  to: string;
  options?: NavigateOptions;
}

export interface ActionProps {
  icon: typeof SvgIcon;
  name: string;
  handleClick: () => void;
}

export interface PageContextProps {
  name: string;
  icon?: typeof SvgIcon;
  to: string;
  showNavRail?: boolean;
  showLogin: boolean;
  showLogout: boolean;
  showOnlyAdmin: boolean;
  actions?: ActionProps[];
  fab?: ActionProps;
}

const defaultPageContexts: PageContextProps[] = [
  { name: 'Home', icon: HomeIcon, to: '/', showNavRail: true, showLogin: true, showLogout: false, showOnlyAdmin: false},
  { name: 'Events', icon: EventIcon, to: '/events', showNavRail: true, showLogin: true, showLogout: false, showOnlyAdmin: false },
  { name: 'Members', icon: PersonIcon, to: '/members', showNavRail: true, showLogin: true, showLogout: false, showOnlyAdmin: false },
  { name: 'Guests', icon: PersonOutlineIcon, to: '/guests', showNavRail: true, showLogin: true, showLogout: false, showOnlyAdmin: true },
  { name: 'Leagues', icon: SportsGolfIcon, to: '/leagues', showNavRail: true, showLogin: true, showLogout: false, showOnlyAdmin: false },
  { name: 'Courses', icon: GolfCourseIcon, to: '/courses', showNavRail: true, showLogin: true, showLogout: false, showOnlyAdmin: false },
  { name: 'Handbook', icon: LibraryBooksIcon, to: '/handbook', showNavRail: true, showLogin: true, showLogout: true, showOnlyAdmin: false },
  { name: "", to: "/course", showLogin: false, showLogout: false, showOnlyAdmin: false },
  { name: "", to: "/event", showLogin: false, showLogout: false, showOnlyAdmin: false },
  { name: "", to: "/league", showLogin: false, showLogout: false, showOnlyAdmin: false },
  { name: "", to: "/profile", showLogin: false, showLogout: false, showOnlyAdmin: false },
];

export interface AppProviderProps {
  children?: ReactNode
}

export interface AppContextModel {
  actions: ActionProps[];
  appTitle: string;
  getPageTitle: () => string;
  isHomePage: boolean;
  pageIcon: typeof SvgIcon;
  pageContexts: PageContextProps[];
  setPageName: (name: string) => void;
  setActions: (actions: ActionProps[]) => void;
}

export const AppContext = React.createContext<AppContextModel>(
  {} as AppContextModel,
);

export function useApp(): AppContextModel {
  return useContext(AppContext)
}

export const AppProvider = ({ children }: AppProviderProps): ReactElement => {

  const location = useLocation();
  const appTitle = "SWGA";
  const pageContexts = defaultPageContexts;
  const [actions, setActions] = useState<ActionProps[]>([]);
  const [isHomePage, setIsHomePage] = useState<boolean>(location.pathname === "/");
  const [pageIcon, setPageIcon] = useState<typeof SvgIcon>(GolfCourseIcon);
  const [pageName, setPageName] = useState<string>("Home");

  useEffect(() => {
    const urlSegments = location.pathname.split("/");
    const urlPath = urlSegments[0] + "/" + urlSegments[1];
    const pageIcon = defaultPageContexts.find((page) => page.to === urlPath)?.icon || GolfCourseIcon;
    // TODO: Is this a race condition? What if setActions happens before the location is updated?
    setActions([]);
    setPageIcon(pageIcon === HomeIcon ? GolfCourseIcon : pageIcon);
    setIsHomePage(location.pathname === "/");
  }, [location.pathname]);

  const getPageTitle = (): string => {
    if (isHomePage) {
      return appTitle;
    } else {
      const urlSegments = location.pathname.split("/");
      const urlPath = urlSegments[0] + "/" + urlSegments[1];
      const pageTitle = defaultPageContexts.find((page) => page.to === urlPath)?.name || "";
      if (pageTitle === "") {
        return pageName;
      } else {
        return pageTitle;
      }

    }
  }

  const values = {
    actions,
    appTitle, 
    getPageTitle,
    isHomePage,
    pageIcon,
    pageContexts,
    setActions,
    setPageName,
  }
  return (
    <AppContext.Provider 
      value={values}
    >
      {children}
    </AppContext.Provider>
  );
}

//export const useUserContext = (): UserContextState => {
//  return useContext(UserStateContext)
//}