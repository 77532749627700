import { ReactElement } from 'react';

import { orderBy, query } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import Box from '@mui/material/Box';

import { List } from '@mui/material';

import { useAuth } from '../../AuthProvider';
import { useData } from '../../DataProvider';
import Course from '../../types/Course';
import Hourglass from '../common/Hourglass';

import { CourseListItem } from './CourseListItem';

export const CourseDirectory = (): ReactElement => {
  const { isRevealingGodsSecrets } = useAuth();
  const { coursesCol } = useData();

  const q = query(coursesCol, orderBy('name'));

  const [courses, isLoading, isError] = useCollectionData<Course>(q);

  if (isLoading) {
    return <Hourglass />;
  } else if (isError) {
    return <Box width="100vw" />;
  } else if (courses) {
    return (
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
        subheader={<li />}
      >
        { courses.flatMap((course: Course) => {
          if (isRevealingGodsSecrets || !course?.isTest) {
            return (<CourseListItem key={course.itemId} course={course} isDividerDisplayed={true} />);
          } else {
            return null;
          }
        })}
      </List>
    );
  } else {
    return <></>;
  }
};
