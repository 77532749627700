import { ReactElement } from 'react';

import { orderBy, query } from 'firebase/firestore';

import { useCollectionData } from 'react-firebase-hooks/firestore';

import Box from '@mui/material/Box';
import List from '@mui/material/List';

import { useAuth } from '../../AuthProvider';
import { useData } from '../../DataProvider';
import League from '../../types/League';
import Hourglass from '../common/Hourglass';

import { LeagueListItem } from './LeagueListItem';

export const LeagueDirectory = (): ReactElement => {
  const { isRevealingGodsSecrets } = useAuth();
  const { leaguesCol } = useData();

  const q = query(leaguesCol, orderBy('name'));

  const [leagues, isLoading, isError] = useCollectionData<League>(q);

  if (isLoading) {
    return <Hourglass />;
  } else if (isError) {
    return <Box width="100vw" />;
  } else if (leagues) {
    return (
      <List
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          p: 0,
          '& ul': { padding: 0 },
          '& li': { padding: 0 },
        }}
        subheader={<li />}
      >
        { leagues.flatMap((league: League) => {
          if (isRevealingGodsSecrets || !league?.isTest) {
            return (<LeagueListItem key={league.itemId} league={league} isDividerDisplayed={true} />);
          } else {
            return null;
          }
        })}
      </List>
    );
  } else {
    return <></>;
  }
};
