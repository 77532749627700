import { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import Fab from '@mui/material/Fab';

import AddIcon from '@mui/icons-material/Add';

import { useAuth } from '../AuthProvider';
import { LeagueDirectory } from '../components/league/LeagueDirectory';
import { useData } from '../DataProvider';

export default function Leagues(): ReactElement {
  const { isGod } = useAuth();
  const { adminOrgs, isOrgAdmin } = useData();

  return (
    <>
    <LeagueDirectory />
    { (isGod || isOrgAdmin(adminOrgs[0])) &&
      <Fab variant="extended" color="primary" aria-label="add course" component={Link} to="/league-create">
        <AddIcon />
        Add league
      </Fab>}
    </>
  );  
}
