import { ReactElement } from "react";

import { ref } from "firebase/storage";

import { Link } from "react-router-dom";

import { useDownloadURL } from "react-firebase-hooks/storage";

import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import GolfCourseIcon from "@mui/icons-material/GolfCourse";

import { imagesRef } from "../../firebase";
import Course from '../../types/Course';

interface CourseRowProps {
  course: Course,
  actions?: ReactElement<typeof Stack>,
  isDividerDisplayed?: boolean
}

export const CourseListItem = (props: CourseRowProps ) : ReactElement => {
  const course = props.course;
  const isDividerDisplayed = props.isDividerDisplayed ?? false;

  const [imageUrl] = useDownloadURL((course && course.logo && course.logo.length > 0) ? ref(imagesRef, course.logo) : null);

  const StyledCourseAvatar = (): ReactElement => {
    return (
      <Avatar 
        alt={course.name} 
        src={imageUrl} 
        sx={{
          height: 48, 
          width: 48, 
          textAlign: "center", 
          alignItems: "center", 
          justifyContent: "center", 
          color: "primary.onContainer", 
          backgroundColor: "primary.container"
        }}>
        <GolfCourseIcon sx={{color: "primary.onContainer", backgroundColor: "primary.container"}}/>
      </Avatar>
    );
  }

  const StyledBasicInfo = (): ReactElement => {
    return (
      (<Stack spacing={1}>
          <Typography variant="overline" sx={{color: "surface.onVariant", py: 0, my: 0, lineHeight: 1 }}>
            {"Par: " + course.par}
          </Typography>
          <Typography variant="caption" sx={{color: "surface.onVariant", py: 0, my: 0, lineHeight: 1 }}>
            {"Tee time spacing: " + course.teeTimeSpacing}
          </Typography>
      </Stack>)
    );
  }

  return (
    <ListItem component={Link} to={`/course/${course.itemId}`} divider={isDividerDisplayed}>
      <ListItemAvatar>
        <StyledCourseAvatar />
      </ListItemAvatar>
      <ListItemText 
        primary={course.name} 
        primaryTypographyProps={{
          variant: "title", 
          color: "surface.contrastText"
        }}
      />
      <ListItemAvatar>
        <StyledBasicInfo />
      </ListItemAvatar>
    </ListItem>
  );
}